import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "./SearchBox.scss";

const MIN_DATE = new Date(2008, 1, 1);
const MAX_DATE = new Date();

const SearchBox = ({
  keyword,
  title,
  startDate,
  endDate,
  handleKeywordChange,
  handleTitleChange,
  handleStartDateChange,
  handleEndDateChange,
  useAltDb,
  handleAltDbChange
}) => {
  const [showAdvanced, setShowAdvanced] = useState(false);

  useEffect(() => {
    const searchBox = document.querySelector('.main-search-wrap input');
    searchBox.focus();
  }, []);

  const toggleAdvancedSearch = () => {
    setShowAdvanced(!showAdvanced);
  };

  if (startDate) {
    startDate = new Date(startDate);
  }
  if (endDate) {
    endDate = new Date(endDate);
  }

  return (
    <div className="search-box">
      <div className="main-search-wrap">
        <div className="main-search search-section">
          <label htmlFor="search">
            <span>Search</span>
            <div className="keyword-control-wrap">
              <input
                id="search"
                name="search"
                placeholder="use quotes for exact searches"
                value={keyword}
                onChange={handleKeywordChange}
                type="search"
              />
              <button type="submit">Go</button>
            </div>
          </label>
        </div>
        <button className="advanced-button" onClick={toggleAdvancedSearch}>
          {showAdvanced ? "Hide Advanced" : "Advanced"}
        </button>
      </div>
      {showAdvanced && (
        <div className="advanced-search">
          <div className="title-wrap">
            <label htmlFor="title">
              <span>Advanced Search</span>
              <input
                id="title"
                name="title"
                value={title}
                onChange={handleTitleChange}
                type="search"
                placeholder="Enter video series, game, or title (optional)"
              />
            </label>
          </div>
          <div className="date-checkbox-wrapper">
            <div className="date-wrap">
              <label>
                <span>Start Date</span>
                <br />
                <DatePicker
                  id="startDate"
                  name="startDate"
                  value={startDate}
                  onChange={handleStartDateChange}
                  format="y-M-d"
                  minDetail="decade"
                  minDate={MIN_DATE}
                  maxDate={MAX_DATE}
                  className="date-picker"
                />
              </label>
            </div>
            <div className="date-wrap">
              <label>
                <span>End Date</span>
                <br />
                <DatePicker
                  id="endDate"
                  name="endDate"
                  value={endDate}
                  onChange={handleEndDateChange}
                  format="y-M-d"
                  minDetail="decade"
                  minDate={MIN_DATE}
                  maxDate={MAX_DATE}
                  className="date-picker"
                />
              </label>
            </div>
            <div className="alt-db-control-wrap">
              <label htmlFor="altDb">
                <span>&#9733; Search DDM / Raw Phil</span>
              </label>
              <input
                id="altDb"
                type="checkbox"
                checked={useAltDb}
                onChange={handleAltDbChange}
              />
            </div>
          </div>
          {/* Include additional advanced search settings here */}
        </div>
      )}
    </div>
  );
};

export default SearchBox;
