import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import WAVES from 'vanta/dist/vanta.waves.min';

const Background = () => {
  const myRef = useRef(null);
  useEffect(() => {
    WAVES({
      el: myRef.current,
      THREE: THREE, // Use the installed three.js version
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      color: 0x32
    });
  }, []);
  return <div ref={myRef} style={{ position: 'fixed', width: '100%', height: '100%', zIndex: -1 }} />;
};

export default Background;
