import React from 'react';
import { BrowserRouter as Router, Switch, Route, NavLink, Link } from 'react-router-dom';
import './App.scss';
import Search from './pages/search/Search';
import About from './pages/about/About';
import Background from './Background';


function App() {
  return (
    <Router>
      <div className="App">
      <Background />
        <header className="App-header">
          <Link className="App-link" to="/">
            <h1>
              <i className="fas fa-piggy-bank"></i> PigPigGo.<strong>RE</strong>
            </h1>
            <br />
          </Link>
          <p>
            <span className="header-text">The ultimate detractor search tool. Now more robust and meaningful.</span>
          </p>
        </header>
        <div className="about-link">
          <a href="https://acksjeeves.com" target="_blank" rel="noopener noreferrer">
            <i className="fas fa-biohazard icon-spacing"></i>
          </a>
          <NavLink activeClassName="link-active" to="/about">
            About
          </NavLink>
          <NavLink activeClassName="link-active" exact to="/">
            Home
          </NavLink>
        </div>
        <div className="main-content">
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/">
              <Search />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
